.mark-down {
  h1 {
    font-size: 24px;
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    font-size: 20px;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
  pre {
    background-color: rgba(149, 149, 165, 0.19);
    padding: 4px 8px;
  }
}